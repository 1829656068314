/*----- 13. Testimonial style  ------*/

.single-testimonial {
  img {
    max-width: 80%;
    border-radius: 50%;
    max-height: 300px;
    min-width: 250px;
  }
  p {
    font-size: 16px;
    font-weight: 500;
    font-style: italic;
    line-height: 32px;

    margin: 1rem 0 0;

    color: #575757;
    @media (max-width: 768px) {
      font-size: 15px;
    }
  }
  .client-info {
    margin: 20px 0 0;
    i {
      font-size: 26px;

      color: #666666;
    }
    h5 {
      font-size: 14px;

      margin: 22px 0 3px;

      letter-spacing: 1.5px;
      text-transform: uppercase;

      color: #010101;
    }
    span {
      font-size: 14px;

      letter-spacing: 1.5px;

      color: #010101;
    }
  }

  &--style2 {
    p {
      font-size: 24px;

      color: #ffffff;
    }
    .client-info {
      h5 {
        color: #ffffff;
      }
      span {
        color: #ffffff;
      }
    }
  }
}

.testimonial-active {
  .swiper-button-next {
    right: 0 !important;
  }
  .swiper-button-prev {
    left: 0 !important;
  }

  &:hover {
    .ht-swiper-button-nav {
      visibility: visible;

      opacity: 1;
    }
  }
}

.testimonial-area {
  &:hover .nav-style-1.owl-carousel > .owl-nav div {
    visibility: visible;

    opacity: 1;
  }
}