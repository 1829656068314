//
// Opacity
// modify the transparency of an element with this quick modifier classes
//
.opacity-0 {
  opacity: 0 !important;
}
.opacity-1 {
  opacity: 0.1 !important;
}
.opacity-2 {
  opacity: 0.2 !important;
}
.opacity-3 {
  opacity: 0.3 !important;
}
.opacity-4 {
  opacity: 0.4 !important;
}
.opacity-5 {
  opacity: 0.5 !important;
}
.opacity-6 {
  opacity: 0.6 !important;
}
.opacity-7 {
  opacity: 0.7 !important;
}
.opacity-8 {
  opacity: 0.8 !important;
}
.opacity-8 {
  opacity: 0.9 !important;
}
.opacity-10 {
  opacity: 1 !important;
}

// Used for fade animations
.animation-enter{
    opacity: 0;
 }
 .animation-exit{
    opacity: 1;
 }
 .animation-enter-active{
    opacity: 1;
 }
 .animation-exit-active{
    opacity: 0;
 }
 .animation-enter-active,
 .animation-exit-active{
    transition: opacity 800ms;
 }
  