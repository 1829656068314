.loading-container {
  position: relative;
  top: 50%;
  left: 50%;
  @media (max-width: 576px) {
    transform: scale(0.75) translate(-100%, 0%);
  }
  @media (min-width: 577px) {
    transform: translate(-60%, 0%);
  }
  @media (min-width: 768px) {
    transform: translate(-50%, 0%);
  }
}
